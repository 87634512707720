.results {
	flex-wrap: wrap;
}
.results p {
	width: 100%;
	padding: 0 0.25rem;
}

/* .title {
	width: 20%;
}
.day {
	width: 7.5%;
}
.time {
	width: 15%;
}
.instructor {
	width: 15%;
}
.song {
	width: 20%;
}
.costume {
	width: 22.5%;
} */
